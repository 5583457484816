import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import { textAlign } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import * as fiActions from "../../../store/fi/fiSlice";
import * as clientActions from "../../../store/fi/fiClientsSlice";

export default function FreeTextInput(values) {
  const dispatch = useDispatch();
  const [color, setColor] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [key, setKey] = useState("");

  const tradeToEdit = useSelector(
    (state) => state.fiSlice?.selectedTradeToEdit,
  );
  const { selectedClient, newClientValues } = useSelector(
    (state) => state.fiClientsSlice,
  );
  const selectedClientKeys = Object.keys(selectedClient);
  useEffect(() => {
    if (values.values.type !== "new") {
      if (
        tradeToEdit !== undefined &&
        values.values.key !== "securityName" &&
        values.values.key !== "bbgMakorCode" &&
        // values.values.key !== "brokerRef" &&
        values.values.key !== "firmName" &&
        values.values.key !== "subAccountsCode" &&
        values.values.key !== "agentName" &&
        values.values.key !== "agentBic" &&
        values.values.key !== "localCode" &&
        values.values.key !== "clearanceAc" &&
        values.values.key !== "lei" &&
        values.values.key !== "account" &&
        values.values.key !== "confirmationEmail1" &&
        values.values.key !== "confirmationEmail2" &&
        values.values.key !== "confirmationEmail3" &&
        values.values.key !== "confirmationEmail4" &&
        values.values.key !== "merrillAcc"
      ) {
        setSelectedValue(tradeToEdit[`${values.values.key}`]);
        const obj = {
          value: tradeToEdit[`${values.values.key}`],
          key: values.values.key,
        };
        dispatch(fiActions.editNewTradeForm(obj));
      } else if (
        tradeToEdit !== undefined &&
        values.values.key === "securityName" &&
        values.values.key !== "bbgMakorCode" &&
        // values.values.key !== "brokerRef" &&
        values.values.key !== "firmName" &&
        values.values.key !== "subAccountsCode" &&
        values.values.key !== "agentName" &&
        values.values.key !== "agentBic" &&
        values.values.key !== "localCode" &&
        values.values.key !== "clearanceAc" &&
        values.values.key !== "lei" &&
        values.values.key !== "account" &&
        values.values.key !== "confirmationEmail1" &&
        values.values.key !== "confirmationEmail2" &&
        values.values.key !== "confirmationEmail3" &&
        values.values.key !== "confirmationEmail4" &&
        values.values.key !== "merrillAcc"
      ) {
        setSelectedValue(tradeToEdit[`security_name`]);
        const obj = {
          value: tradeToEdit[`security_name`],
          key: values.values.key,
        };
        dispatch(fiActions.editNewTradeForm(obj));
      } else if (
        values.values.key === "bbgMakorCode" ||
        // values.values.key === "brokerRef" ||
        values.values.key === "firmName" ||
        values.values.key === "subAccountsCode" ||
        values.values.key === "agentName" ||
        values.values.key === "agentBic" ||
        values.values.key === "localCode" ||
        values.values.key === "clearanceAc" ||
        values.values.key === "lei" ||
        values.values.key === "account" ||
        values.values.key === "confirmationEmail1" ||
        values.values.key === "confirmationEmail2" ||
        values.values.key === "confirmationEmail3" ||
        values.values.key === "confirmationEmail4" ||
        values.values.key === "merrillAcc"
      ) {
        if (
          newClientValues.ssi !== selectedClient.ssi &&
          selectedClientKeys.length > 0
        ) {
          if (
            newClientValues.ssi !== "FDNY" &&
            (values.values.key === "agentName" ||
              values.values.key === "agentBic" ||
              values.values.key === "localCode" ||
              values.values.key === "clearanceAc")
          ) {
            const obj = {
              value: "",
              key: values.values.key,
            };
            dispatch(clientActions.editNewClientValues(obj));
            setSelectedValue("");
          } else if (
            newClientValues.ssi === "FDNY" &&
            values.values.key === "account"
          ) {
            const obj = {
              value: "",
              key: values.values.key,
            };
            dispatch(clientActions.editNewClientValues(obj));
            setSelectedValue("");
          } else {
            setSelectedValue(selectedClient[`${values.values.key}`]);
            const obj = {
              value: selectedClient[`${values.values.key}`],
              key: values.values.key,
            };
            dispatch(clientActions.editNewClientValues(obj));
          }
        } else if (selectedClientKeys.length > 0) {
          setSelectedValue(selectedClient[`${values.values.key}`]);
          const obj = {
            value: selectedClient[`${values.values.key}`],
            key: values.values.key,
          };
          dispatch(clientActions.editNewClientValues(obj));
        }
      }
    }
  }, []);

  useEffect(() => {
    setKey(values.values.key);
  }, [values.values.key]);
  // useEffect(() => {

  // }, [newClientValues]);

  const useStyles = makeStyles((theme) => ({
    freeTextPicker: {
      marginTop: "25px",
      "& .MuiSelect-select:not([multiple]) option": {
        backgroundColor: "#2D2D2D",
      },
      "& .MuiOutlinedInput-input": {
        color: "#FFFFFF",
        borderRadius: "4px",
        height: "32px",
        width: "210px",
        padding: 0,
        textAlign: "left",
        paddingLeft: "10px",
      },
      "& .MuiInputLabel-root": {
        color: "#868DA2",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#686B76",
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "&:hover .MuiInputLabel-root": {
        color: "#2692FF",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2692FF",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#2692FF",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2692FF",
      },
    },
  }));
  
  const classes = useStyles();

  const handleChange = (event) => {
    setColor(!color);
    setSelectedValue(event.target.value);
    const obj = {
      value: event.target.value,
      key: values.values.key,
    };
    if (
      values.values.key !== "bbgMakorCode" &&
      // values.values.key !== "brokerRef" &&
      values.values.key !== "firmName" &&
      values.values.key !== "subAccountsCode" &&
      values.values.key !== "agentName" &&
      values.values.key !== "agentBic" &&
      values.values.key !== "localCode" &&
      values.values.key !== "clearanceAc" &&
      values.values.key !== "lei" &&
      values.values.key !== "account" &&
      values.values.key !== "confirmationEmail1" &&
      values.values.key !== "confirmationEmail2" &&
      values.values.key !== "confirmationEmail3" &&
      values.values.key !== "confirmationEmail4" &&
      values.values.key !== "merrillAcc"
    ) {
      dispatch(fiActions.editNewTradeForm(obj));
    } else if (
      values.values.key === "bbgMakorCode" ||
      // values.values.key === "brokerRef" ||
      values.values.key === "firmName" ||
      values.values.key === "subAccountsCode" ||
      values.values.key === "agentName" ||
      values.values.key === "agentBic" ||
      values.values.key === "localCode" ||
      values.values.key === "clearanceAc" ||
      values.values.key === "lei" ||
      values.values.key === "account" ||
      values.values.key === "confirmationEmail1" ||
      values.values.key === "confirmationEmail2" ||
      values.values.key === "confirmationEmail3" ||
      values.values.key === "confirmationEmail4" ||
      values.values.key === "merrillAcc"
    ) {
      dispatch(clientActions.editNewClientValues(obj));
    }

    // }
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          className={classes.freeTextPicker}
          style={{
            width:
              key === "merrillAcc"
                ? "90px"
                : key === "isin" || key === "securityName"
                ? "220px"
                : "210px",
            marginTop: key === "merrillAcc" ? "0" : "25px",
          }}
          id="outlined-select-currency-native"
          type="text"
          label={values.values.label}
          value={selectedValue}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          inputProps={{
            maxLength: key === "merrillAcc" ? 8 : undefined,
            pattern: key === "merrillAcc" ? "^[a-zA-Z0-9]*$" : undefined,
          }}
        ></TextField>
      </div>
    </form>
  );
}
