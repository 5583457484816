import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { StrategyTable } from "./tables/StrategyTable";
import { UnderlyingTable } from "./tables/UnderlyingTable";
import { BookingTable } from "./tables/BookingTable";
import { BookingTextEditor } from "./components/BookingTextEditor";

export const BookingTables = ({
  parsedBookingText,
  makorStaticData,
  order,
  gridApi,
  setIsUnderlyingLoading,
  isSubmittingForm,
  bookedTablesData,
  bookedPayloadData,
  isSymphony,
  setIsSymphony,
}) => {
  useEffect(() => {
    if (
      bookedPayloadData !== null &&
      order.status.toLowerCase().includes("booked")
    ) {
      const result = bookedPayloadData.tablesData.bookingTable.filter((row) => {
        if (row.id !== "client") return false;

        // Dynamically check all properties that start with 'exec'
        return Object.keys(row).some(
          (key) =>
            key.startsWith("exec") &&
            row[key].toLowerCase() === "goldman sachs",
        );
      });
      if (result.length > 0) setIsSymphony(true);
    }
  }, [bookedPayloadData, order]);

  useEffect(() => {
    console.log({ isSymphony });
  }, [isSymphony]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      {/* Left side: BookingTable */}
      <Grid item xs={6}>
        <BookingTable
          parsedBookingText={parsedBookingText}
          order={order}
          makorStaticData={makorStaticData}
          isSubmittingForm={isSubmittingForm}
          bookedTablesData={bookedTablesData}
        />
      </Grid>

      {/* Right side: StrategyTable, UnderlyingTable, and Editors */}
      <Grid
        container
        item
        xs={5}
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: "1000px" }}
      >
        <Grid item>
          <StrategyTable
            parsedBookingText={parsedBookingText}
            order={order}
            gridApiSource={gridApi}
            isSubmittingForm={isSubmittingForm}
            bookedTablesData={bookedTablesData}
          />
        </Grid>
        <Grid item>
          <UnderlyingTable
            parsedBookingText={parsedBookingText}
            order={order}
            gridApiSource={gridApi}
            setIsUnderlyingLoading={setIsUnderlyingLoading}
            isSubmittingForm={isSubmittingForm}
          />
        </Grid>

        {/* Editor for FOG, HOUSE, and Symphony */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {/* FOG and HOUSE Editor */}
          {bookedPayloadData !== null &&
          order.equityIndex.toLowerCase() !== "index" &&
          order.status.toLowerCase().includes("booked") ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              xs={6}
            >
              <Grid item style={{ width: "100%" }}>
                <BookingTextEditor
                  label="FOG"
                  bookedPayloadData={bookedPayloadData}
                  field="fogText"
                  order={order}
                />
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <BookingTextEditor
                  label="HOUSE"
                  bookedPayloadData={bookedPayloadData}
                  field="houseText"
                  order={order}
                />
              </Grid>
            </Grid>
          ) : null}

          {/* Symphony Editor */}
          {bookedPayloadData !== null &&
          isSymphony &&
          order.status.toLowerCase().includes("booked") ? (
            <Grid
              item
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="flex-start"
              xs={6}
            >
              <BookingTextEditor
                label="Symphony"
                bookedPayloadData={bookedPayloadData}
                field="symphonyText"
                order={order}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
