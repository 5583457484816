import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { Grid } from "@material-ui/core";
import "./style.css";
import { ToggleRenderer } from "../utils/ToggleRenderer";
import { useDispatch } from "react-redux";
import * as derivsPricerSlice from "../../../../../store/derivsPricer/derivsPricerSlice";

export const StrategyTable = ({
  parsedBookingText,
  order,
  gridApiSource,
  isSubmittingForm,
  bookedTablesData,
}) => {
  // ag grid settings
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "700px" }), []);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "strategy",
      editable: order._strategy.toLowerCase() === "custom" ? true : false,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "quantity",
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "price",
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "ratio",
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "delta",
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      valueFormatter: deltaValueFormatter,
    },
    {
      field: "tie",
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
    {
      field: "isOtc",
      editable: false,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
      valueFormatter: otcFormatter,
      cellRenderer: ToggleRenderer,
    },
    {
      field: "exchange",
      editable: true,
      lockPosition: true,
      suppressMovable: true,
      suppressMenu: true,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.id;
  });
  const onCellKeyDown = useCallback(
    (e) => {
      if (e.event && !order.status.toLowerCase().includes("booked")) {
        var keyPressed = e.event.code;
        const rowIndex = e.node.rowIndex;
        const colId = e.column.colId;
        if (
          (keyPressed === "Space" && e.colDef.field === "isOtc") ||
          (keyPressed === "Enter" && e.colDef.field === "isOtc")
        ) {
          const newValue = e.value === false ? true : false;
          setRowData((pre) => [
            {
              ...pre[0],
              isOtc: newValue,
              exchange: newValue === false ? parsedBookingText.exchange : "",
            },
          ]);
          setColumnDefs(
            columnDefs.map((column) => {
              if (column.field === "exchange") {
                return {
                  ...column,
                  editable: newValue === false ? true : false,
                };
              } else return column;
            }),
          );
        } else if (keyPressed === "Space" && e.colDef.editable) {
          gridApi.startEditingCell({
            rowIndex: rowIndex,
            colKey: colId,
          });
        }
      }
    },
    [gridColumnApi, order],
  );
  const onCellEditRequest = useCallback(
    (e) => {
      const colId = e.column.colId;
      const value = e.value;
      console.log({ colId, value });
      if (colId !== "exchange" && colId !== "strategy") {
        if (!isNaN(value)) {
          setRowData((pre) => [{ ...pre[0], [`${colId}`]: value }]);
        }
      } else if (colId === "strategy") {
        setRowData((pre) => [
          { ...pre[0], [`${colId}`]: value, _strategy: "custom" },
        ]);
      } else setRowData((pre) => [{ ...pre[0], [`${colId}`]: value }]);
    },
    [rowData],
  );

  // useEffect
  useEffect(() => {
    const nodesList = gridApiSource.getRenderedNodes();
    setRowData([
      {
        id: 0,
        strategy: order._strategy,
        quantity: parsedBookingText.quantity,
        price:
          parsedBookingText.price !== undefined
            ? parsedBookingText.price
            : "N/A",
        ratio: order.ratio,
        delta: parsedBookingText.delta,
        tie: order.tie,
        isOtc: false,
        exchange: parsedBookingText.exchange,
      },
    ]);
  }, []);

  useEffect(() => {
    dispatch(derivsPricerSlice.setStrategyTable([...rowData]));
  }, [rowData]);

  useEffect(() => {
    if (isSubmittingForm) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isSubmittingForm, gridApi]);

  useEffect(() => {
    if (order.status.toLowerCase().includes("booked")) {
      if (bookedTablesData !== null) {
        setRowData(bookedTablesData.strategyTable);
      }
      setColumnDefs(
        columnDefs.map((c) => {
          return { ...c, editable: false };
        }),
      );
    }
  }, [order]);

  return (
    <>
      {rowData.length > 0 ? (
        <Grid
          item
          className="ag-theme-balham-dark strategy-table"
          style={gridStyle}
          xs={12}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            domLayout={"autoHeight"}
            onGridReady={onGridReady}
            animateRows={true}
            readOnlyEdit={true}
            singleClickEdit={true}
            onCellKeyDown={onCellKeyDown}
            onCellEditRequest={onCellEditRequest}
            enableCellEditingOnBackspace={true}
          ></AgGridReact>
        </Grid>
      ) : null}
    </>
  );
};

export const otcFormatter = (params) => {
  return `${params.value === false ? "No" : "Yes"}`;
};
export const deltaValueFormatter = (params) => {
  if (params.value !== undefined) {
    return `${`${Number((params.value * 100).toFixed(2))}%`}`;
  } else {
    return undefined;
  }
};
