import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { END_POINT } from "../../utils/index";
import * as actionSnackBar from "../snackbar/action";

export let fiClientsSlice = createSlice({
  name: "fiClientsSlice",
  initialState: {
    clientsList: { items: [], isLoading: true },
    tradersList: { tradersListObj: {}, isLoading: true },
    scrollPageParams: 30,
    newClientValues: {
      ssi: "",
      bbgMakorCode: "",
      // brokerRef: "",
      firmName: "",
      subAccountsCode: "",
      agentName: "",
      agentBic: "",
      localCode: "",
      clearanceAc: "",
      lei: "",
      account: "",
      confirmationEmail1: "",
      confirmationEmail2: "",
      confirmationEmail3: "",
      confirmationEmail4: "",
      company: "",
      merrillAcc: "",
      clientType:"client",
    },
    selectedClient: {},
    searchValue: "",
  },
  reducers: {
    getClientsList: (state, action) => {
      state.clientsList.items = action.payload;
      state.clientsList.isLoading = false;
    },
    resetClientsList: (state, action) => {
      state.clientsList.items = [];
      state.clientsList.isLoading = true;
    },
    getTradersList: (state, action) => {
      state.tradersList.tradersListObj = action.payload;
      state.tradersList.isLoading = false;
    },
    setScrollPageParams: (state, action) => {
      state.scrollPageParams = action.payload;
    },
    editNewClientValues: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;
      if (key === "ssi" && value === "FDNY") {
        state.newClientValues = { ...state.newClientValues, account: "" };
      } else if (key === "ssi" && value !== "FDNY") {
        state.newClientValues = { ...state.newClientValues, agentName: "" };
        state.newClientValues = { ...state.newClientValues, agentBic: "" };
        state.newClientValues = { ...state.newClientValues, localCode: "" };
        state.newClientValues = { ...state.newClientValues, clearanceAc: "" };
      }
      state.newClientValues = { ...state.newClientValues, [`${key}`]: value };
    },
    clearNewClientValues: (state) => {
      state.newClientValues = {
        ssi: "",
        bbgMakorCode: "",
        // brokerRef: "",
        firmName: "",
        subAccountsCode: "",
        agentName: "",
        agentBic: "",
        localCode: "",
        clearanceAc: "",
        lei: "",
        account: "",
        confirmationEmail1: "",
        confirmationEmail2: "",
        confirmationEmail3: "",
        confirmationEmail4: "",
        company: "",
        merrillAcc: "",
        clientType: "client",
      };
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
  },
});

export const getClientsListAsync = () => async (dispatch, getState) => {
  const token = sessionStorage.getItem("token");
  const scrollPageParams = getState().fiClientsSlice.scrollPageParams;

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_CLIENTS +
        "/get_clients",
      { scrollPageParams },
      {
        headers: { Authorization: token },
      }
    );

    dispatch(getClientsList(res.data.clientsList));
    dispatch(setScrollPageParams(res.data.scrollPageParams));
  } catch (error) {
    console.log("getClientsListAsync", error);
  }
};
export const searchClientAsync =
  (searchValue) => async (dispatch, getState) => {
    const token = sessionStorage.getItem("token");

    dispatch(setSearchValue(searchValue));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` +
          END_POINT.FI_CLIENTS +
          "/search_client",
        { searchValue },
        {
          headers: { Authorization: token },
        }
      );
      dispatch(getClientsList(res.data));
      dispatch(setScrollPageParams(22));
    } catch (error) {
      console.log("searchClientAsync", error);
    }
  };

export const createNewClientThunk =
  (handleClose) => async (dispatch, getState) => {
    const newClientValues = getState().fiClientsSlice.newClientValues;
    const scrollPageParams = getState().fiClientsSlice.scrollPageParams;
    const messageHandler = [];
    // const formKeys = Object.keys(newClientValues);
    const formKeys = [
      "bbgMakorCode",
      // "brokerRef",
      "firmName",
      "subAccountsCode",
      "lei",
      "ssi",
      "company",
      "account",
      "agentName",
      "agentBic",
      "localCode",
      "clearanceAc",
      "confirmationEmail1",
      "confirmationEmail2",
      "confirmationEmail3",
      "confirmationEmail4",
      "clientType",
    ];
    formKeys.map((key) => {
      if (
        newClientValues.ssi === "FDNY" &&
        key !== "confirmationEmail1" &&
        key !== "confirmationEmail2" &&
        key !== "confirmationEmail3" &&
        key !== "confirmationEmail4" &&
        key !== "account" &&
        key !== "agentName" &&
        key !== "agentBic" &&
        key !== "localCode" &&
        key !== "clearanceAc" &&
        (newClientValues[`${key}`] === "" || newClientValues[`${key}`] === null)
      ) {
        messageHandler.push(`${key} field is mandatory`);
      } else if (
        newClientValues.ssi !== "FDNY" &&
        newClientValues.ssi !== "" &&
        key !== "confirmationEmail1" &&
        key !== "confirmationEmail2" &&
        key !== "confirmationEmail3" &&
        key !== "confirmationEmail4" &&
        key !== "agentName" &&
        key !== "agentBic" &&
        key !== "localCode" &&
        key !== "clearanceAc" &&
        (newClientValues[`${key}`] === "" || newClientValues[`${key}`] === null)
      ) {
        messageHandler.push(`${key} field is mandatory`);
      } else if (
        newClientValues.ssi === "" &&
        (newClientValues[`${key}`] === "" || newClientValues[`${key}`] === null)
      ) {
        messageHandler.push(`${key} field is mandatory`);
      }
    });
    if (messageHandler.length !== 0) {
      dispatch(actionSnackBar.setSnackBar("error", messageHandler[0], 3000));
    } else if (messageHandler.length === 0) {
      const token = sessionStorage.getItem("token");
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_CLIENTS +
            "/create_client",
          { newClientValues, scrollPageParams },
          {
            headers: { Authorization: token },
          }
        );
        if (res.status === 200) {
          if (res.data.status !== undefined) {
            dispatch(
              actionSnackBar.setSnackBar("error", res.data.message, 3000)
            );
          } else {
            dispatch(getClientsList(res.data));
            dispatch(clearNewClientValues());
            handleClose();
          }
        }
      } catch (error) {
        console.log("createNewClientThunk", error);
      }
    }
  };
export const UpdateClientDetailsThunk =
  (handleClose) => async (dispatch, getState) => {
    const newClientValues = getState().fiClientsSlice.newClientValues;
    const scrollPageParams = getState().fiClientsSlice.scrollPageParams;
    const messageHandler = [];
    // const formKeys = Object.keys(newClientValues);
    const formKeys = [
      "bbgMakorCode",
      // "brokerRef",
      "firmName",
      "subAccountsCode",
      "lei",
      "ssi",
      "company",
      "account",
      "agentName",
      "agentBic",
      "localCode",
      "clearanceAc",
      "confirmationEmail1",
      "confirmationEmail2",
      "confirmationEmail3",
      "confirmationEmail4",
    ];
    formKeys.map((key) => {
      if (
        newClientValues.ssi === "FDNY" &&
        key !== "confirmationEmail1" &&
        key !== "confirmationEmail2" &&
        key !== "confirmationEmail3" &&
        key !== "confirmationEmail4" &&
        key !== "account" &&
        key !== "agentName" &&
        key !== "agentBic" &&
        key !== "localCode" &&
        key !== "clearanceAc" &&
        (newClientValues[`${key}`] === "" || newClientValues[`${key}`] === null)
      ) {
        messageHandler.push(`${key} field is mandatory`);
      } else if (
        newClientValues.ssi !== "FDNY" &&
        key !== "confirmationEmail1" &&
        key !== "confirmationEmail2" &&
        key !== "confirmationEmail3" &&
        key !== "confirmationEmail4" &&
        key !== "agentName" &&
        key !== "agentBic" &&
        key !== "localCode" &&
        key !== "clearanceAc" &&
        (newClientValues[`${key}`] === "" || newClientValues[`${key}`] === null)
      ) {
        messageHandler.push(`${key} field is mandatory`);
      } else if (
        newClientValues.ssi === "" &&
        (newClientValues[`${key}`] === "" || newClientValues[`${key}`] === null)
      ) {
        messageHandler.push(`${key} field is mandatory`);
      } 
    });
    if (messageHandler.length !== 0) {
      dispatch(actionSnackBar.setSnackBar("error", messageHandler[0], 3000));
    } else if (messageHandler.length === 0) {
      const token = sessionStorage.getItem("token");
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}` +
            END_POINT.FI_CLIENTS +
            "/update_client",
          { updatedClientValues: newClientValues, scrollPageParams },
          {
            headers: { Authorization: token },
          }
        );
        if (res.status === 200) {
          if (res.data.status !== undefined) {
            dispatch(
              actionSnackBar.setSnackBar("error", res.data.message, 3000)
            );
          } else {
            dispatch(getClientsList(res.data));
            dispatch(clearNewClientValues());
            handleClose();
          }
        }
      } catch (error) {
        console.log("UpdateClientDetailsThunk", error);
      }
    }
  };

export const deleteCLientThunk = () => async (dispatch, getState) => {
  const selectedClient = getState().fiClientsSlice.selectedClient;
  const scrollPageParams = getState().fiClientsSlice.scrollPageParams;

  const token = sessionStorage.getItem("token");

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.FI_CLIENTS +
        "/delete_client",
      { clientId: selectedClient.id, scrollPageParams },
      {
        headers: { Authorization: token },
      }
    );
    if (res.status === 200) {
      dispatch(getClientsList(res.data));
    }
  } catch (error) {
    console.log("deleteCLientThunk", error);
  }
};
export const handleCheckboxChange = (e, id) => async (dispatch, getState) => {
  const clientsList = getState().fiClientsSlice.clientsList;
  let copyClientList = [...clientsList.items];
  copyClientList = copyClientList.map((client) => {
    if (Number(client.id) === Number(id)) {
      client = { ...client, checked: e.target.checked };
      if (e.target.checked) {
        dispatch(setSelectedClient(client));
      } else {
        dispatch(setSelectedClient({}));
      }
    } else {
      client = { ...client, checked: false };
    }
    return client;
  });
  dispatch(getClientsList(copyClientList));
};
export const handleRowSelect = (client) => async (dispatch, getState) => {
  const clientsList = getState().fiClientsSlice.clientsList;
  let copyClientList = [...clientsList.items];
  // console.log(client);
  copyClientList = copyClientList.map((c) => {
    if (Number(client.id) === Number(c.id)) {
      if (c.checked === undefined || !c.checked) {
        c = { ...c, checked: true };
        dispatch(setSelectedClient(client));
      } else {
        c = { ...c, checked: false };
        dispatch(setSelectedClient({}));
      }
    } else {
      c = { ...c, checked: false };
    }
    return c;
  });
  dispatch(getClientsList(copyClientList));
};

export const {
  getClientsList,
  setScrollPageParams,
  getTradersList,
  editNewClientValues,
  clearNewClientValues,
  setSelectedClient,
  setSearchValue,
  resetClientsList,
} = fiClientsSlice.actions;
export default fiClientsSlice.reducer;
